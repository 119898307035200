import React from "react";
import GellyballBanner from "../images/Heros/GellyBall-Hero.jpg";
import { Layout, Seo } from "../components";
import GellyballHead from "../images/CardHeads/Gellyball-Head.png";
import BookingCardImg from "../images/BookingImgs/Booking-CardGB.jpg";

// import ProductContext from "../context/ProductContext";

export default function GellyballPage() {
	const jumboTexts = [
		<p>A low-impact game of "tag"</p>,
		<p>Players shoot small gelatin-soft rounds</p>,
		<p>Best for Ages 5 and Up, Office Parties, or Team Building</p>,
	];

	const FAQTexts = [
		{
			bodyText: [
				<h4>What to wear: </h4>,
				<p>
					This is an outdoor action sport in the woods, so dress in weather
					appropriate active gear. BDU's or a hoodie and jeans, you do you.
				</p>,
				<p>
					It's a good idea for clothing to cover exposed skin and to wear good
					footwear that protects the ankle.
				</p>,
				<p>Gellyballs are non-marking and non-staining.</p>,
			],
		},
		{
			bodyText: [
				<h4>What to bring:</h4>,
				<p>We provide complete rental kits.</p>,
				<p>Plenty of sports drinks and water are also a good idea.</p>,
			],
		},
		{
			bodyText: [
				<h4>What to expect:</h4>,
				<p>
					Because gellyball is very low impact, it's a great game for younger
					kids or adults looking to try out an action sport that's less intense
					than paintball or airsoft. Gellyball is also great for workplace team
					building activities, bachelor/bachelorette parties, and birthday
					parties.
				</p>,
			],
		},
	];

	// const { collections } = useContext(ProductContext);

	return (
		<>
			<Layout
				gameType='gellyball'
				heroImg={GellyballBanner}
				page=''
				headerText='Nitehawk Gellyball'
				bodyText={jumboTexts}
				btnText=''
				btnRoute=''
				contentsDown='form'
				defaultSport='gellyball'
				FAQHeaderImg={GellyballHead}
				FAQtexts={FAQTexts}
				BookingCardImg={BookingCardImg}
				altText='Gellyball Information'>
				<Seo title='Gellyball' description='NiteHawk gellyball page' />
			</Layout>
		</>
	);
}
